/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.deliveryAddressPopUpWhenNoAddressBlock {
  &_isArabic {
    direction: rtl;
  }
  &-stylePopUp,
  &-false {
    .deliveryAddressPopUpWhenNoAddressOuterBlock {
      @include desktop {
        position: fixed;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .deliveryAddressPopUpWhenNoAddressPopUp {
        @include mobile {
          height: 300px;
          width: 100%;
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          background: #ffffff;
          padding: 10px 20px;
          z-index: 100;
          border-radius: 12px 12px 0 0;
        }

        @include desktop {
          width: 400px;
          height: 300px;
          background: #ffffff;
          padding: 20px 20px;
          z-index: 100;
          border-radius: 12px;
          border: 1px solid #d1d3d4;
        }
      }

      .deliveryAddressPopUpWhenNoAddressInnerBlock {
        .greetingToUser {
          .headingText {
            font-family: Avenir Next;
            font-weight: 600;
            line-height: 24px;
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .deliveryNote {
          p {
            font-family: Avenir Next;
            font-size: 14px;
            font-weight: 400;
            color: #616161;
          }
        }

        .addNewAddressButton {
          margin: 20px 0;
          button {
            height: 40px;
            width: 100%;
            line-height: 40px;
            border-radius: 5px;
            font-weight: 700;
            font-size: 14px;
            background: #000000;
            color: #ffffff;
          }
        }

        .partition {
          position: relative;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
        }

        .partition::before,
        .partition::after {
          content: "";
          display: inline-block;
          width: 45%;
          border-top: 1px solid #c8c6c6;
          position: absolute;
          top: 60%;
          transform: translateY(-50%);
        }

        .partition::before {
          left: 0;
        }

        .partition::after {
          right: 0;
        }

        .selectLocation {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 20px 0;

          img {
            width: 25px !important;
            height: 30px !important;
          }

          .selectLocationText {
            font-family: Avenir Next;
            font-size: 14px;
            font-weight: 500;
            color: #0b92d3;
          }
        }
      }
    }
  }
  .deliveryAddressPopUpWhenNoAddressOuterBlock {
    @include desktop {
      position: absolute;
      top: 6px;
      z-index: 100;
    }

    .deliveryAddressPopUpWhenNoAddressPopUp {
      @include mobile {
        height: 300px;
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffff;
        padding: 10px 20px;
        z-index: 100;
        border-radius: 12px 12px 0 0;
      }

      @include desktop {
        width: 364px;
        height: 290px;
        background: #ffffff;
        padding: 10px 20px;
        z-index: 100;
        border-radius: 4px;
        border: 1px solid #d1d3d4;
      }
    }

    .deliveryAddressPopUpWhenNoAddressInnerBlock {
      .greetingToUser {
        .headingText {
          font-family: Avenir Next;
          font-weight: 600;
          line-height: 24px;
          font-size: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .deliveryNote {
        p {
          font-family: Avenir Next;
          font-size: 14px;
          font-weight: 400;
          color: #616161;
        }
      }

      .addNewAddressButton {
        margin: 20px 0;
        button {
          height: 40px;
          width: 100%;
          line-height: 40px;
          border-radius: 5px;
          font-weight: 700;
          font-size: 14px;
          background: #000000;
          color: #ffffff;
        }
      }

      .partition {
        position: relative;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
      }

      .partition::before,
      .partition::after {
        content: "";
        display: inline-block;
        width: 45%;
        border-top: 1px solid #c8c6c6;
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
      }

      .partition::before {
        left: 0;
      }

      .partition::after {
        right: 0;
      }

      .selectLocation {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0;

        img {
          width: 25px !important;
          height: 30px !important;
        }

        .selectLocationText {
          font-family: Avenir Next;
          font-size: 14px;
          font-weight: 500;
          color: #0b92d3;
        }
      }
    }
  }
}
