/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MenuBrands {
    width: 100%;
    &-ContentWrapper {
        span,
        a {
            color: $black;
        }
        a {
            font-weight: var(--fw-medium);
            .Image {
                width: 80px;
                .LazyLoad-wrapper {
                    // padding: 35px;
                    width: 80px;
                    height: auto;
                }
                &-Image {
                    border-radius: 50%;
                    padding: 0 5px;
                }
            }
        }
        &_isArabic {
            a {
                .Image {
                    margin-left: 0;
                    margin-right: -20px;
                }
            }
        }
    }
    &-Title {
        text-transform: uppercase;
        width: 100%;
    }
    &-MobileSliderWrapper {
        .Slider-Wrapper {
            max-width: 67%;
            a {
                align-items: center;
                display: flex;
                flex-direction: column;
                font-size: 9px;
                font-weight: var(--fw-medium);
                line-height: 16px;
                width: 80px;
                text-indent: 0;
                .Image {
                    margin-bottom: 10px;
                }
            }
        }
    }
    @include before-desktop {
        &-ContentWrapper {
            margin-top: 20px;
            .MenuBrands-Title {
                text-align: left;
                display: block;
            }
            &-Brands {
                display: flex;
                margin-left: 5px;
                padding-right: 2px;
                text-align: center;
                a {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    font-size: 9px;
                    font-weight: var(--fw-medium);
                    line-height: 16px;
                    margin-left: 0;
                    padding: 0 5px;
                    width: 80px;
                    text-indent: 0;
                }
                &_isArabic {
                    direction: ltr;
                }
                div {
                    width: 100%;
                }
            }
        }
    }
    @include desktop {
        margin: 20px 0 30px 35px;
        width: 23%;
        &-ContentWrapper {
            width: 100%;
            height: 100%;
            overflow: hidden;
            &-Brands {
                display: flex;
                //flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
                overflow-x: auto;
                padding-right: 17px;
                width: 100%;
                height: 100%;
                box-sizing: content-box;
                height: 344px;
                justify-content: flex-start;
                margin-top: 10px;
                margin-bottom: 5px;
                scrollbar-width: thin;
                scrollbar-color: #585757 #dcdcdc;
                &::-webkit-scrollbar {
                    width: 6px;
                    /* width of the entire scrollbar */
                }
                &::-webkit-scrollbar-track {
                    background: #dcdcdc;
                    /* color of the tracking area */
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #585757;
                    /* color of the scroll thumb */
                    border-radius: 0px;
                    /* roundness of the scroll thumb */
                    border: 0px solid white;
                    /* creates padding around scroll thumb */
                }
                a {
                    margin-left: 0;
                    margin-left: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 50%;
                    line-height: 1.3;
                    margin-bottom: 20px;
                    padding-right: 10px;
                }
                &_isArabic {
                    padding-right: 0;
                    padding-left: 17px;
                    a {
                        padding-right: 0px;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}