/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MenuGrid {
    width: 23%;
    &-ItemLabel {}
    &-Content {
        background-color: #ffffff;
        pointer-events: all;
        span,
        a {
            color: #000000;
        }
    }
    &-Columns {
        display: flex;
        height: 100%;
    }
    &-Column {
        width: 100%;
        &:not(:last-child) {
            border-right: solid 1px var(--grey);
        }
        span {
            text-transform: uppercase;
            width: 100%;
        }
        &-Content {
            display: grid;
            grid-template-columns: auto auto;
            margin-top: 10px;
            a {
                margin: 0;
            }
        }
    }
    @include before-desktop {
        margin-top: 5px;
        position: relative;
        width: 100%;
        &-Content {
            margin: 0;
            width: 100%;
            &_isArabic {
                button {
                    margin-left: 0;
                    margin-right: -30px;
                }
            }
        }
        &-Columns {
            flex-direction: column;
            text-align: center;
             :last-child {
                margin-right: 0;
            }
            >div {
                margin: 0;
            }
        }
        &-Title {            
            font-weight: var(--fw-bold);
            display: block;
            text-align: left;
        }
        &-Column {
            line-height: 30px;
            width: 100%;
            &:not(:last-child) {
                border-right: 0;
            }
            .ViewAll {
                &-Link {
                    display: none;
                    font-weight: var(--fw-bold);
                    text-align: center;
                }
                &-Button {                    
                    a{
                        &:first-child {
                            display: block;
                        } 
                    } 
                }
            }
            &-Content {
                grid-auto-flow: row;
                grid-template-columns: 1fr 1fr;
                a {
                    line-height: 20px;
                    margin: 16px 0;
                    text-align: center;
                    &:not(:nth-child(-n + 5)) {
                        display: none;
                    }
                    &:first-child {
                        display: none;
                    }
                    &:last-child {
                        margin: 16px;
                    }
                    .Image {
                        padding-bottom: 16px!important;
                        &-Image {
                            border-radius: 50%;
                        }
                    }
                }
                &_isArabic {
                    a {
                        .Image {
                            margin-left: 0;
                            margin-right: -20px;
                        }
                    }
                }
            }
            &_isAllShow {
                background-color: #ffffff;                
                width: 100%;
                .MenuGrid {
                    &-Column {
                        &-Content {
                            position: relative;
                            a {
                                &:not(:nth-child(-n + 4)) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include desktop {
        margin: 20px 0 30px 35px;
        &-Content {
            &_isArabic {
                .MenuGrid {
                    &-Column {
                        border-left: solid 1px var(--grey);
                        border-right: 0;
                        margin-left: 0;
                        margin-right: 0;
                        &:nth-last-child(1) {
                            border-left: 0;
                        }
                        &-content {}
                    }
                }
            }
        }
        a {
            font-weight: var(--fw-medium);
            margin-left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 50%;
            line-height: 1.3;
            margin-bottom: 20px;
            padding-right: 10px;
        }
        a:first-child {
            display: none;
        }
        &-Column {
            width: 100%;
            height: 100%;
            overflow: hidden;
            &:hover {
                overflow: unset;
            }
            &-Content {
                display: flex;
                // display: grid;
                // grid-template-columns: repeat(2, 1fr);
                // flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
                overflow-x: auto;
                padding-right: 17px;
                width: 100%;
                height: 100%;
                box-sizing: content-box;
                height: 344px;
                // justify-content: flex-start;
                margin-top: 10px;
                margin-bottom: 3px;
                scrollbar-width: thin;
                scrollbar-color: #585757 #dcdcdc;
                &::-webkit-scrollbar {
                    width: 6px;
                    /* width of the entire scrollbar */
                }
                &::-webkit-scrollbar-track {
                    background: #dcdcdc;
                    /* color of the tracking area */
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #585757;
                    /* color of the scroll thumb */
                    border-radius: 0px;
                    /* roundness of the scroll thumb */
                    border: 0px solid white;
                    /* creates padding around scroll thumb */
                }
                a {
                    div:not(.MenuGrid-ItemLabel) {
                        display: none;
                    }
                }
                &_isArabic {
                    padding-right: 0;
                    padding-left: 17px;
                    a {
                        padding-right: 0;
                        padding-left: 10px;
                    }
                }
            }
            .ViewAll {
                &-Link {
                    display: none;
                }
                &-Button {
                    display: none;
                }
            }
        }
    }
}