// Colors
$default-primary-base-color: #ff5637;
$default-primary-dark-color: #d12228;
$default-primary-light-color: #ad9d9a;

$color-turquoise: #28d9aa;
$color-cardinal: #d12228;
$color-tundora: #4a4a4a;
$color-nobel: #9b9b9b;
$color-silver: #cdcdcd;
$color-pure-black: #000000;

// Fonts
$font-family: "Avenir Next";
$cairo-font-family: 'Cairo';

$font-display: swap;

$fw-ultra-light: 100;
$fw-thin: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-demi: 600;
$fw-bold: 700;
$fw-heavy: 800;

$fs-normal: normal;
$fs-italic: italic;
