/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.cityAreaAddressSelection {
  position: relative;
  cursor: pointer;

  &_isArabic {
    direction: rtl;
  }

  .showBackgroundColor {
    background: #f5f5f5;
  }

  .cityAreaDropdown {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      color: #f5f5f5;
      margin: 0 5px;
    }

    @include desktop {
      min-width: 105px;
      max-width: 155px;
    }

    @include mobile {
      height: 36px;
      border-radius: 10px;
      padding: 3px;
      margin: 0 3px;
    }

    .locationImage {
      height: 19px;
      width: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 3px;
    }

    .showEllipsisArea {
      max-width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cityAreaText {
      font-family: Avenir Next;
      font-size: 13px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .colorBlack {
    color: #000000;
  }

  .colorBlue {
    color: #0b92d3;
  }
}

@include mobile {
  min-width: 35%;
  max-width: 90%;
  margin: 0 5px;
  .MyAccountAddressBook-Express {
    &-ContentWrapper {
      &_formContent {
        .popUpBackArrow {
          svg {
            position: fixed;
            z-index: 9999;
            top: 10px;
            left: 5px;
            height: 40px;
            width: 10px;
          }
        }
      }
      .MyAccountAddressPopup {
        width: 100%;
        height: 100vh;
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 65px 10px;

        &_isArabic {
          direction: rtl;
        }
      }
    }
  }
}

@include desktop {
  .MyAccountAddressBook-Express {
    &-ContentWrapper {
      &_formContent {
        .MyAccountAddressPopup {
          width: 542px;
          height: 650px;
          z-index: 100;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 30px;
          border-radius: 10px;

          &_isArabic {
            direction: rtl;
          }

          .MyAccountAddressFieldForm {
            input,
            .FieldSelect-Select {
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
