/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.DynamicContent {
  text-indent: 0;
  @include mobile {
    padding: 0 10px;
  }
  hr {
    border-top: 1px solid #efefef;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    width: var(--main-container-width-dsk);
    max-width: var(--container-max-width);
    @include tablet {
      width: calc(100vw - 60px);
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include mobile {
      width: calc(100vw - 20px);
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
