h1 {
    font-size: 22px;
    letter-spacing: 0;
    font-weight: var(--fw-demi);
    margin: .8rem 0;
}

h2 {
    font-size: 15px;
    letter-spacing: .25px;
    font-weight: var(--fw-bold);
}

h3 {
    font-size: 17px;
    font-weight: var(--fw-demi);
    letter-spacing: -0.61px;
}

